



































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import Pagination from '#/components/Pagination.vue'

import { NUM_OF_ORDERS_PER_PAGE, OrdersStore } from '#/store/orders'
import { ModalStore } from '~/store/modal'
import { fetchCustomerEmails } from '#/api/order'

@Component({ components: { VLoadSpinner, Pagination } })
export default class OrdersPage extends Vue {
    @Prop({ type: Number }) pageIndex!: number

    urlForCustomerEmailsTextFile: string = null

    @ModalStore.State('isLoading') isLoading: boolean
    @OrdersStore.State('sales') sales: IOrderProducer[]
    @OrdersStore.State('orderPages') orderPages: number

    @OrdersStore.Action('fetchProducerSales') fetchProducerSales: ({ limit, offset }: { limit: number; offset: number }) => void

    _calculateTotal(boughtBeats: IOrderProducer['bought_beats_light']) {
        return boughtBeats.reduce((a, b) => a + b.producer_income, 0)
    }

    _fetchNewOrders(offset: number, pageIndex: number) {
        this.fetchProducerSales({ limit: NUM_OF_ORDERS_PER_PAGE, offset })
        this.$router.push({ name: 'Orders', params: { page: pageIndex.toString() } })
    }

    _generateAndDownloadCsv(data: Array<{ email: string }>) {
        // map the data
        const dataRows = data.map(item => [item.email])
        // create csv file
        const csvContent = 'data:text/csv;charset=utf-8,' + dataRows.map(e => e.join(';')).join('\n')
        // download
        const encodedUri = encodeURI(csvContent)
        window.open(encodedUri)
    }

    async _exportCustomerEmails({}) {
        const customers = await fetchCustomerEmails()
        this._generateAndDownloadCsv(customers)
    }

    mounted() {
        this.fetchProducerSales({ limit: NUM_OF_ORDERS_PER_PAGE, offset: (this.pageIndex - 1) * NUM_OF_ORDERS_PER_PAGE })
    }
}
